<template>
  <div class="home">
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center justify-content-center">
      <div class="container position-relative">
        <h1 style="font-family: 'Oleo Script', Sans-serif;">"It's Your Day"</h1>
        <h2>The best look to complete your special moment.</h2>
        <a href="#product" class="btn-get-started scrollto">Pesan Sekarang</a>
      </div>
    </section><!-- End Hero -->
    <main id="main">
      
      <!-- ======= About Section ======= -->
      <section id="product" class="about">
        <div class="container">

          <div class="row">
            <div class="col-lg-6">
              <img src="assets/img/upload1.jpg" class="img-fluid" alt="" style="border-radius: 3%;">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <h3>Sewa Box Seserahan</h3>
              <p>
                Kotak seserahan yang lucu dan cantik sekarang bisa kamu miliki tanpa harus mengeluarkan biaya mahal. Sewa sekarang dan buat acaramu lebih spesial!
              </p>
              <div class="row">
                <div class="col-md-12 ">
                  <router-link
                    :to="{name:'Seserahan'}"
                    type="button" class="btn btn-warning"
                    data-wow-delay=".6s"
                    style="color: white; "
                  >
                  Lihat Galeri
                  </router-link> &nbsp;
                  <a type="button" class="btn btn-warning scrollto"
                    data-wow-delay=".6s"
                    style="color: white;"
                    @click="scrollToPricingAndClickTab('seserahan')">Pilih Paket</a>

                </div>
              </div>
            </div>
            <hr class="mt-4 mb-4">
            <div class="col-lg-6">
              <img src="assets/img/upload3.jpg" class="img-fluid" alt="" style="border-radius: 3%;">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <h3>Mahar Pernikahan</h3>
              <!-- <p>
                Undangan digital kekinian untuk momen bahagiamu! Desainnya cantik dan mudah digunakan, pesan sekarang juga!
              </p> -->
              <div class="row">
                <div class="col-md-12 ">
                  <router-link
                    :to="{name: 'Seserahan', hash: '#mahar'}"
                    type="button"
                    class="btn btn-warning"
                    data-wow-delay=".6s"
                    style="color: white;"
                  >
                    Lihat Galeri
                  </router-link>
                  
                  &nbsp;
                  <a type="button" class="btn btn-warning scrollto"
                    data-wow-delay=".6s"
                    style="color: white;"
                    @click="scrollToPricingAndClickTab('mahar')">Pilih Paket</a>
                </div>
              </div>
            </div>
            <hr class="mt-4 mb-4">
            <!-- <div class="col-lg-6">
              <img src="assets/img/upload3.jpg" class="img-fluid" alt="" style="border-radius: 3%;">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <h3>Ring Box</h3>
              <div class="row">
                <div class="col-md-12 ">
                  <router-link
                    :to="{name: 'Seserahan', hash: '#ringbox'}"
                    type="button"
                    class="btn btn-warning"
                    data-wow-delay=".6s"
                    style="color: white;"
                  >
                    Lihat Galeri
                  </router-link>
                  
                &nbsp;
                <a type="button" class="btn btn-warning scrollto"
                    data-wow-delay=".6s"
                    style="color: white;"
                    @click="scrollToPricingAndClickTab('ringbox')">Pilih Paket</a>
                </div>
              </div>
            </div>
            <hr class="mt-4 mb-4">
            <div class="col-lg-6">
              <img src="assets/img/upload3.jpg" class="img-fluid" alt="" style="border-radius: 3%;">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <h3>Jewelry Box</h3>
              <div class="row">
                <div class="col-md-12 ">
                  <router-link
                    :to="{name: 'Seserahan', hash: '#jewelry'}"
                    type="button"
                    class="btn btn-warning"
                    data-wow-delay=".6s"
                    style="color: white;"
                  >
                    Lihat Galeri
                  </router-link>
                  
                  &nbsp;
                </div>
              </div>
            </div>
            <hr class="mt-4 mb-4"> -->
            <div class="col-lg-6">
              <img src="assets/img/digital_invitation.png" class="img-fluid" alt="" style="border-radius: 3%;">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <h3>Undangan Digital</h3>
              <p>
                Undangan digital kekinian untuk momen bahagiamu! Desainnya cantik dan mudah digunakan, pesan sekarang juga!
              </p>
              <div class="row">
                <div class="col-md-12 ">
                  <router-link
                    :to="{name:'Template'}"
                    type="button" class="btn btn-warning"
                    data-wow-delay=".6s"
                    style="color: white; "
                  >
                  Lihat Template
                  </router-link>
                  &nbsp;
                  <a type="button" class="btn btn-warning scrollto"
                    data-wow-delay=".6s"
                    style="color: white;"
                    @click="scrollToPricingAndClickTab('undangan')">Pilih Paket</a>
                </div>
              </div>
            </div>
            
            <!-- <div class="col-lg-6">
              <img src="assets/img/about.jpg" class="img-fluid" alt="" style="border-radius: 3%;">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <h3>Happines Box</h3>
              <p>
                Hadirkan senyum di wajah orang tersayang dengan hadiah spesial dari Happines Box.
              </p>
              <div class="row">
                <div class="col-md-12 ">
                  <button type="button" class="btn btn-warning" style="color: white;">Lihat Galeri</button>
                </div>
              </div>
            </div> -->
          </div>

        </div>
      </section><!-- End About Section -->

      <section id="promo" class="services" style="background-color: rgb(255, 242, 216);"  v-if="getVoucher && getVoucher.status === 1">
        <div class="container text-center">

          <div class="section-title">
            <h2>Hot Promo</h2>
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12 mt-2">
                  <a href="/claim-voucher">
                    <img :src="'https://admin.itsyourdayofficial.com/image_banner/' + getVoucher.image_banner" class="img-fluid" alt="" style="">
                  </a>
                </div>  
            </div>   
            <div class="justify-content-center mt-5">
                 <a href="/claim-voucher" type="button" class="btn btn-warning btn-lg" style="color: white;">Claim Voucher</a>
            </div>   
          </div>

        </div>
      </section>
      <!-- ======= Services Section ======= -->
      <section id="services" class="services">
        <div class="container text-center">

          <div class="section-title">
            <h2>Fitur & Keunggulan</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
          </div>

          <div class="col-md-12">
            <ul id="features-filter" class="nav nav-pills mb-5 justify-content-center" >
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="pill" href="#seserahan" role="tab" >Kotak Seserahan</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#undangan" role="tab">Undangan Digital</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link"  data-bs-toggle="pill" href="#happines" role="tab">Happines Box</a>
              </li> -->
            </ul>
          </div>

          <div class="row">

            <div class="tab-content" >
              <!-- SESERAHAN SECTION -->
              <div class="tab-pane fade show active" id="seserahan" role="tabpanel" aria-labelledby="seserahan-tab">
                <div class="row">
                  <!-- <div class="text-center">
                      <div class="section-title text-center mb-60">
                          <p class="wow fadeInUp" data-wow-delay=".4s">
                            𝑩𝒐𝒐𝒌𝒊𝒏𝒈 𝒔𝒆𝒌𝒂𝒓𝒂𝒏𝒈 𝒅𝒂𝒏 𝒅𝒂𝒑𝒂𝒕𝒌𝒂𝒏 𝒅𝒊𝒔𝒄𝒐𝒖𝒏𝒕 𝒖𝒑 𝒕𝒐 50% 𝑶𝑭𝑭 𝒕𝒂𝒏𝒑𝒂 𝑫𝑷, 𝒃𝒆𝒃𝒂𝒔 𝒑𝒊𝒍𝒊𝒉 𝒕𝒂𝒏𝒈𝒈𝒂𝒍 𝒅𝒂𝒏 𝒖𝒃𝒂𝒉 𝒕𝒂𝒏𝒈𝒈𝒂𝒍 𝒔𝒆𝒘𝒂 𝒌𝒂𝒑𝒂𝒏𝒑𝒖𝒏 𝒔𝒆𝒍𝒂𝒎𝒂 𝒕𝒂𝒏𝒈𝒈𝒂𝒍 𝒕𝒆𝒓𝒔𝒆𝒅𝒊𝒂. 𝑩𝒐𝒐𝒌 𝒔𝒆𝒌𝒂𝒓𝒂𝒏𝒈 𝒔𝒆𝒘𝒂 𝒏𝒂𝒏𝒕𝒊!
                          </p>
                      </div>
                  </div> -->
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box iconbox-blue">
                      <div class="icon">
                        <i class='bx bxl-dropbox' ></i>
                      </div>
                      <h4><a href="">Kotak Seserahan Kualitas Premium</a></h4>
                      <p>Nikmati pilihan beragam model Box Seserahan berkualitas terbaik yang memancarkan kemewahan dari setiap sudutnya. Hadir dalam berbagai ukuran yang menarik, cocok untuk melengkapi momen istimewa Anda.</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                    <div class="icon-box iconbox-orange ">
                      <div class="icon">
                        <i class='bx bxs-layer-plus' ></i>
                      </div>
                      <h4><a href="">Wooden Tray</a></h4>
                      <p>Wooden tray (nampan kayu) yang terbuat dari kayu berkualitas, kokoh, dan menambah kesan elegan.</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                    <div class="icon-box iconbox-pink">
                      <div class="icon">
                        <i class='bx bx-diamond'></i>
                      </div>
                      <h4><a href="">Ring Box</a></h4>
                      <p>Tingkatkan keanggunan cincin pernikahan Anda dengan menyimpannya dalam Ring Box yang indah, menambahkan sentuhan kemewahan dan pesona ekstra pada hari istimewa Anda.</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box iconbox-yellow">
                      <div class="icon">
                        <i class='bx bxs-florist' ></i>
                      </div>
                      <h4><a href="">Bunga Hias Artificial</a></h4>
                      <p>Menggunakan bunga artificial berkualitas premium yang sangat mirip dengan bunga asli. Selain itu, kami menyusun seserahan dengan hati-hati agar barang-barangnya tidak tersembunyi oleh hiasan yang berlebihan.</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                    <div class="icon-box iconbox-red">
                      <div class="icon">
                        <i class="lni lni-money-protection"></i>
                      </div>
                      <h4><a href="">Murah Berkualitas</a></h4>
                      <p>Anda bisa yakin bahwa harga sewa Box Seserahan kami adalah yang paling terjangkau, namun tetap terjaga kulalitasnya.</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                    <div class="icon-box iconbox-teal">
                      <div class="icon">
                        <i class="lni lni-angellist"></i>
                      </div>
                      <h4><a href="">Tanpa DP, Cukup Booking Saja!</a></h4>
                      <p>Booking tanpa pembayaran uang muka (DP), Anda dapat dengan bebas mengubah tanggal acara kapan pun dan membatalkannya kapan saja tanpa ada uang hangus.</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- UNDANGAN SECTION -->
              <div class="tab-pane fade" id="undangan" role="tabpanel" aria-labelledby="undangan-tab">
                <div class="row">
                  <div class="text-center">
                      <div class="section-title text-center mb-60">
                          <p class="wow fadeInUp" data-wow-delay=".4s">
                            Nikmati semua fitur ini di semua template undangan yang tersedia. Cukup pilih template undangan yang sesuai dengan preferensi Anda.
                          </p>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box iconbox-blue">
                      <div class="icon">
                        <i class='bx bxl-telegram'></i>
                      </div>
                      <h4><a href="">Personalisasi Undangan</a></h4>
                      <p>Kirim undangan akan lebih personal dengan nama tamu yang tertulis dalam undangan</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                    <div class="icon-box iconbox-orange ">
                      <div class="icon">
                        <i class='bx bxs-music' ></i>
                      </div>
                      <h4><a href="">Latar Musik</a></h4>
                      <p>Bebas pilih latar musik favoritmu, agar undanganmu lebih hidup</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                    <div class="icon-box iconbox-pink">
                      <div class="icon">
                        <i class='bx bxs-book-content' ></i>
                      </div>
                      <h4><a href="">Reservasi & Buku Tamu</a></h4>
                      <p>Dapatkan konfirmasi kehadiran tamu, ucapan dan do’a dari mereka</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box iconbox-yellow">
                      <div class="icon">
                        <i class='bx bxs-photo-album' ></i>
                      </div>
                      <h4><a href="">Galeri Foto</a></h4>
                      <p>Bagikan momen bahagia dengan memasang foto tanpa batas</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                    <div class="icon-box iconbox-red">
                      <div class="icon">
                        <i class='bx bxs-map-pin' ></i>
                      </div>
                      <h4><a href="">Google Maps</a></h4>
                      <p>Permudah tamu untuk menemukan lokasi acara pernikahanmu</p>
                    </div>
                  </div>
                  <!-- <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                    <div class="icon-box iconbox-teal">
                      <div class="icon">
                        <i class='bx bxs-timer' ></i>
                      </div>
                      <h4><a href="">Hitung Mundur</a></h4>
                      <p>Ingatkan momen pernikahanmu, agar tidak terlewatkan</p>
                    </div>
                  </div> -->
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                    <div class="icon-box iconbox-teal">
                      <div class="icon">
                        <i class='bx bxs-timer' ></i>
                      </div>
                      <h4><a href="">Hitung Mundur</a></h4>
                      <p>Ingatkan momen pernikahanmu, agar tidak terlewatkan</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- HAPPINES SECTION -->
              <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
            </div>
          </div>

        </div>
      </section><!-- End Services Section -->

      <!-- ======= Pricing Section ======= -->
      <section id="pricing" class="pricing">
        <div class="container">

          <div class="section-title">
            <h2>Temukan Paket Idealmu di Sini</h2>
            <p>Temukan beragam pilihan paket menarik dengan harga yang sangat terjangkau untuk membuat momen istimewamu semakin berkesan!</p>
          </div>

          <div class="col-md-12">
            <ul id="features-filter" class="nav nav-pills mb-3 justify-content-center" role="tab-list">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="pill" href="#price_seserahan" role="tab" id="seserahan-tab">Kotak Seserahan</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#price_mahar" role="tab" id="mahar-tab">Mahar Frame</a>
              </li>              
              <!-- <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#price_ringbox" role="tab" id="ringbox-tab">Ring Box</a>
              </li>               -->
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#price_undangan" role="tab" id="undangan-tab">Undangan Digital</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#price_bundling" role="tab">Bundling Package</a>
              </li>
            </ul>
          </div>

          <div class="row justify-content-center">
          
            <div class="tab-content" >
              <!-- SESERAHAN SECTION -->
              <div class="tab-pane fade show active" id="price_seserahan" role="tabpanel" aria-labelledby="seserahan-tab">
                <div class="col-md-12">
                  
                  <ul id="features-filter" class="nav nav-pills justify-content-center" >
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="pill" href="#pearl" role="tab" >Pearl Tray</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="pill" href="#crystal" role="tab">Crystal Tray</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link"  data-bs-toggle="pill" href="#terrarium" role="tab">Terrarium Tray</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link"  data-bs-toggle="pill" href="#acrylic" role="tab">Acrylic Tray</a>
                    </li>
                  </ul>
                </div>

                <div class="tab-content">
                    <div id="pearl" class="tab-pane fade show active">
                      <!-- <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mb-4">
                          <img src="assets/img/pearl tray 2.png" class="img-fluid" alt="" style="border-radius: 3%;">
                        </div>
                      </div> -->


                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                          <div class="box featured">
                            <h3>Basic Package</h3>
                            <h4><sup>Rp.</sup>480.000</h4>
                            <ul>
                              <li>8 Box</li>
                              <li>Pearl Tray</li>
                              <li>Bunga hias artificial premium</li>
                              <li>Free sewa ring box</li>                  
                              <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a href="https://bit.ly/3S8ypeu" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                              <br><br>
                              <router-link
                                :to="{name: 'Seserahan', hash: '#seserahan-pearl'}"
                                type="button"
                                class="btn-buy"
                                data-wow-delay=".6s"
                                style="background:#BCA37F;"
                              >
                                Lihat Galeri
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="crystal" class="tab-pane fade">
                        <!-- crystal tray -->
                        
                        <!-- <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6 mb-4">
                            <img src="assets/img/crystal tray.jpeg" class="img-fluid" alt="" style="border-radius: 3%;">
                          </div>
                        </div> -->

                        <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6">
                            <div class="box featured">
                              <h3>Bronze Package</h3>
                              <h4><sup>Rp.</sup>560.000</h4>
                              <ul>
                                <li>8 Box</li>
                                <li>Crystal Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Free sewa ring box</li>                  
                                <li>Include cutting name ring box</li>
                              </ul>
                              <div class="btn-wrap">
                                <a href="https://bit.ly/3S8ypeu" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                <br><br>
                                <router-link
                                  :to="{name: 'Seserahan', hash: '#seserahan-crystal'}"
                                  type="button"
                                  class="btn-buy"
                                  data-wow-delay=".6s"
                                  style="background:#BCA37F;"
                                >
                                  Lihat Galeri
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div id="terrarium" class="tab-pane fade">
                        <!-- terrarium -->

                        <!-- <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6 mb-4">
                            <img src="assets/img/terarrium tray.jpg" class="img-fluid" alt="" style="border-radius: 3%;">
                          </div>
                        </div> -->

                        <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6">
                            <div class="box featured">
                              <h3>Bronze Package</h3>
                              <h4><sup>Rp.</sup>400.000</h4>
                              <ul>
                                <li>8 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                              </ul>
                              <div class="btn-wrap">
                                <a href="https://bit.ly/3S8ypeu" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                <br><br>
                                <router-link
                                  :to="{name: 'Seserahan', hash: '#seserahan-terarium'}"
                                  type="button"
                                  class="btn-buy"
                                  data-wow-delay=".6s"
                                  style="background:#BCA37F;"
                                >
                                  Lihat Galeri
                                </router-link>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
                            <div class="box featured">
                              <h3>Silver Package</h3>
                              <h4><sup>Rp.</sup>500.000</h4>
                              <ul>
                                <li>10 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                              </ul>
                              <div class="btn-wrap">
                                <a href="https://bit.ly/3S8ypeu" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                <br><br>
                                <router-link
                                  :to="{name: 'Seserahan', hash: '#seserahan-terarium'}"
                                  type="button"
                                  class="btn-buy"
                                  data-wow-delay=".6s"
                                  style="background:#BCA37F;"
                                >
                                  Lihat Galeri
                                </router-link>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
                            <div class="box featured">
                              <span class="advanced">Best Seller</span>
                              <h3>Gold Package</h3>
                              <h4><sup>Rp.</sup>600.000</h4>
                              <ul>
                                <li>12 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                              </ul>
                              <div class="btn-wrap">
                                <a href="https://bit.ly/3S8ypeu" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                <br><br>
                                <router-link
                                  :to="{name: 'Seserahan', hash: '#seserahan-terarium'}"
                                  type="button"
                                  class="btn-buy"
                                  data-wow-delay=".6s"
                                  style="background:#BCA37F;"
                                >
                                  Lihat Galeri
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div id="acrylic" class="tab-pane fade">
                        <!-- acrylic  -->

                        <!-- <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6 mb-4">
                            <img src="assets/img/acrylic tray.jpeg" class="img-fluid" alt="" style="border-radius: 3%;">
                          </div>
                        </div> -->

                        <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6">
                            <div class="box featured">
                              <h3>Bronze Package</h3>
                              <h4><sup>Rp.</sup>335.000</h4>
                              <ul>
                                <li>8 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                              </ul>
                              <div class="btn-wrap">
                                <a href="https://bit.ly/3S8ypeu" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                <br><br>
                                <router-link
                                  :to="{name: 'Seserahan', hash: '#seserahan-acrylic'}"
                                  type="button"
                                  class="btn-buy"
                                  data-wow-delay=".6s"
                                  style="background:#BCA37F;"
                                >
                                  Lihat Galeri
                                </router-link>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
                            <div class="box featured">
                              <h3>Silver Package</h3>
                              <h4><sup>Rp.</sup>405.000</h4>
                              <ul>
                                <li>10 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                              </ul>
                              <div class="btn-wrap">
                                <a href="https://bit.ly/3S8ypeu" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                <br><br>
                                <router-link
                                  :to="{name: 'Seserahan', hash: '#seserahan-acrylic'}"
                                  type="button"
                                  class="btn-buy"
                                  data-wow-delay=".6s"
                                  style="background:#BCA37F;"
                                >
                                  Lihat Galeri
                                </router-link>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
                            <div class="box featured">
                              <span class="advanced">Best Seller</span>
                              <h3>Gold Package</h3>
                              <h4><sup>Rp.</sup>475.000</h4>
                              <ul>
                                <li>12 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                              </ul>
                              <div class="btn-wrap">
                                <a href="https://bit.ly/3S8ypeu" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                <br><br>
                                <router-link
                                  :to="{name: 'Seserahan', hash: '#seserahan-acrylic'}"
                                  type="button"
                                  class="btn-buy"
                                  data-wow-delay=".6s"
                                  style="background:#BCA37F;"
                                >
                                  Lihat Galeri
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
              <!-- UNDANGAN SECTION -->
              <div class="tab-pane fade" id="price_undangan" role="tabpanel" aria-labelledby="undangan-tab">
                <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-6">
                    <div class="box featured">
                      <h3>Basic Package</h3>
                      <p style="font-size: 26px; margin-bottom:10px;"><sup>Rp.</sup><s>100.000</s></p>
                      <h4><sup>Rp.</sup>49.000</h4>
                      <ul>
                        <li>Unlimited Nama Tamu</li>
                        <li>10 kuota galeri foto</li>
                        <li>Hitung Mundur Acara</li>
                        <li>Detail Infromasi Acara</li>
                        <li>Kirim Ucapan dan Konfirmasi Kehadiran</li>                  
                        <li>Bebas Pilih Latar Musik</li>
                        <li class="na">Maps Lokasi</li>
                        <li class="na">Story Kisah Cinta</li>
                        <li class="na">Amplop Digital (Max 4 Rekening)</li>
                        <li class="na">Konfirmasi Kehadiran by WhatsApp</li>
                        <li class="na">Turut Mengundang</li>
                        <li class="na">Sub Domain Nama Mempelai</li>
                      </ul>
                      <div class="btn-wrap">
                        <a href="https://forms.gle/6DDmh5fcF7bYfVtcA" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                        <br><br>
                        <router-link
                          :to="{name:'Template'}"
                          type="button" class="btn btn-warning"
                          data-wow-delay=".6s"
                          style="color: white; "
                        >
                        Lihat Template
                        </router-link>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
                    <div class="box featured">
                      <h3>Standard Package</h3>
                      <p style="font-size: 26px; margin-bottom:10px;"><sup>Rp.</sup><s>125.000</s></p>
                      <h4><sup>Rp.</sup>69.000</h4>
                      <ul>
                        <li>Unlimited Nama Tamu</li>
                        <li>12 kuota galeri foto</li>
                        <li>Hitung Mundur Acara</li>
                        <li>Detail Infromasi Acara</li>
                        <li>Kirim Ucapan dan Konfirmasi Kehadiran</li>                  
                        <li>Bebas Pilih Latar Musik</li>
                        <li>Maps Lokasi</li>
                        <li>Story Kisah Cinta</li>
                        <li>Amplop Digital (Max 4 Rekening)</li>
                        <li class="na">Konfirmasi Kehadiran by WhatsApp</li>
                        <li class="na">Turut Mengundang</li>
                        <li class="na">Sub Domain Nama Mempelai</li>
                      </ul>
                      <div class="btn-wrap">
                        <a href="https://forms.gle/6DDmh5fcF7bYfVtcA" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                        <br><br>
                        <router-link
                          :to="{name:'Template'}"
                          type="button" class="btn btn-warning"
                          data-wow-delay=".6s"
                          style="color: white; "
                        >
                        Lihat Template
                        </router-link>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
                    <div class="box featured">
                      <span class="advanced">Best Seller</span>
                      <h3>Premium Package</h3>
                      <p style="font-size: 26px; margin-bottom:10px;"><sup>Rp.</sup><s>150.000</s></p>
                      <h4><sup>Rp.</sup>99.000</h4>
                      <ul>
                        <li>Unlimited Nama Tamu</li>
                        <li>12 kuota galeri foto</li>
                        <li>Hitung Mundur Acara</li>
                        <li>Detail Infromasi Acara</li>
                        <li>Kirim Ucapan dan Konfirmasi Kehadiran</li>                  
                        <li>Bebas Pilih Latar Musik</li>
                        <li>Maps Lokasi</li>
                        <li>Story Kisah Cinta</li>
                        <li>Amplop Digital (Max 4 Rekening)</li>
                        <li>Konfirmasi Kehadiran by WhatsApp</li>
                        <li>Turut Mengundang</li>
                        <li>Sub Domain Nama Mempelai</li>
                      </ul>
                      <div class="btn-wrap">
                        <a href="https://forms.gle/6DDmh5fcF7bYfVtcA" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                        <br><br>
                        <router-link
                          :to="{name:'Template'}"
                          type="button" class="btn btn-warning"
                          data-wow-delay=".6s"
                          style="color: white; "
                        >
                        Lihat Template
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- BUNDLING SECTION -->
              <div class="tab-pane fade" id="price_bundling" role="tabpanel" aria-labelledby="bundling-tab">

                <div class="col-md-12">
                  
                  <ul id="features-filter" class="nav nav-pills justify-content-center" >
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="pill" href="#pearl-bundling" role="tab" >Pearl Tray</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="pill" href="#crystal-bundling" role="tab">Crystal Tray</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link"  data-bs-toggle="pill" href="#terrarium-bundling" role="tab">Terrarium Tray</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link"  data-bs-toggle="pill" href="#acrylic-bundling" role="tab">Acrylic Tray</a>
                    </li>
                  </ul>
                </div>

                <div class="tab-content">
                    <div id="pearl-bundling" class="tab-pane fade show active">
                      <!-- PEARL BUNDLING -->
                      
                      <!-- <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mb-4">
                          <img src="assets/img/pearl tray 2.png" class="img-fluid" alt="" style="border-radius: 3%;">
                        </div>
                      </div> -->

                      <div class="row justify-content-center">
                        <div class="col-lg-4 pt-4 col-md-6">
                          <div class="box featured">
                            <h3>Basic Package + Undangan Digital (Basic)</h3>
                            <h4><sup>Rp.</sup>510.000</h4>
                            <ul>
                              <li>8 Box</li>
                              <li>Pearl Tray</li>
                              <li>Bunga hias artificial premium</li>
                              <li>Free sewa ring box</li>                  
                              <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 pt-4 col-md-6 mt-4 mt-md-0">
                          <div class="box featured">
                            <h3>Basic Package + Undangan Digital (Standar)</h3>
                            <h4><sup>Rp.</sup>520.000</h4>
                            <ul>
                              <li>10 Box</li>
                              <li>Pearl Tray</li>
                              <li>Bunga hias artificial premium</li>
                              <li>Free sewa ring box</li>                  
                              <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Basic Package + Undangan Digital (Premium)</h3>
                            <h4><sup>Rp.</sup>550.000</h4>
                            <ul>
                              <li>12 Box</li>
                              <li>Pearl Tray</li>
                              <li>Bunga hias artificial premium</li>
                              <li>Free sewa ring box</li>                  
                              <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>                        
                      </div>
                    </div>
                    <div id="crystal-bundling" class="tab-pane fade">
                      <!-- crystal tray -->
                       
                      <!-- <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mb-4">
                          <img src="assets/img/crystal tray.jpeg" class="img-fluid" alt="" style="border-radius: 3%;">
                        </div>
                      </div> -->

                      <div class="row justify-content-center">
                        <div class="col-lg-4 pt-4 col-md-6">
                          <div class="box featured">
                            <h3>Bronze Package + Undangan Digital (Basic)</h3>
                            <h4><sup>Rp.</sup>590.000</h4>
                            <ul>
                              <li>8 Box</li>
                              <li>Crystal Tray</li>
                              <li>Bunga hias artificial premium</li>
                              <li>Free sewa ring box</li>                  
                              <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 pt-4 col-md-6 mt-4 mt-md-0">
                          <div class="box featured">
                            <h3>Bronze Package + Undangan Digital (Standar)</h3>
                            <h4><sup>Rp.</sup>609.000</h4>
                            <ul>
                              <li>10 Box</li>
                              <li>Crystal Tray</li>
                              <li>Bunga hias artificial premium</li>
                              <li>Free sewa ring box</li>                  
                              <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Bronze Package + Undangan Digital (Premium)</h3>
                            <h4><sup>Rp.</sup>629.000</h4>
                            <ul>
                              <li>12 Box</li>
                              <li>Crystal Tray</li>
                              <li>Bunga hias artificial premium</li>
                              <li>Free sewa ring box</li>                  
                              <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>                        
                      </div>
                    </div>

                    <div id="terrarium-bundling" class="tab-pane fade">
                      <!-- terrarium -->
                      <!-- <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mb-4">
                          <img src="assets/img/terarrium tray.jpg" class="img-fluid" alt="" style="border-radius: 3%;">
                        </div>
                      </div> -->
                      
                      <div class="row justify-content-center">
                        <div class="col-lg-4 pt-4 col-md-6">
                          <div class="box featured">
                            <h3>Bronze Package + Undangan Digital (Basic)</h3>
                            <h4><sup>Rp.</sup>420.000</h4>
                            <ul>
                                <li>8 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 pt-4 col-md-6 mt-4 mt-md-0">
                          <div class="box featured">
                            <h3>Silver Package + Undangan Digital (Basic)</h3>
                            <h4><sup>Rp.</sup>520.000</h4>
                            <ul>
                                <li>10 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Gold Package + Undangan Digital (Basic)</h3>
                            <h4><sup>Rp.</sup>620.000</h4>
                            <ul>
                                <li>12 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div> 
                        
                        <div class="col-lg-4 pt-4 col-md-6">
                          <div class="box featured">
                            <h3>Bronze Package + Undangan Digital (Standar)</h3>
                            <h4><sup>Rp.</sup>440.000</h4>
                            <ul>
                                <li>8 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 pt-4 col-md-6 mt-4 mt-md-0">
                          <div class="box featured">
                            <h3>Silver Package + Undangan Digital (Standar)</h3>
                            <h4><sup>Rp.</sup>540.000</h4>
                            <ul>
                                <li>10 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Gold Package + Undangan Digital (Standar)</h3>
                            <h4><sup>Rp.</sup>640.000</h4>
                            <ul>
                                <li>12 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div> 

                        <div class="col-lg-4 pt-4 col-md-6">
                          <div class="box featured">
                            <h3>Bronze Package + Undangan Digital (Premium)</h3>
                            <h4><sup>Rp.</sup>470.000</h4>
                            <ul>
                                <li>8 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 pt-4 col-md-6 mt-4 mt-md-0">
                          <div class="box featured">
                            <h3>Silver Package + Undangan Digital (Premium)</h3>
                            <h4><sup>Rp.</sup>570.000</h4>
                            <ul>
                                <li>10 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Gold Package + Undangan Digital (Premium)</h3>
                            <h4><sup>Rp.</sup>670.000</h4>
                            <ul>
                                <li>12 Terarium Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                    <div id="acrylic-bundling" class="tab-pane fade">
                      <!-- acrylic  -->

                      <!-- <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mb-4">
                          <img src="assets/img/acrylic tray.jpeg" class="img-fluid" alt="" style="border-radius: 3%;">
                        </div>
                      </div> -->


                      <div class="row justify-content-center">
                        <div class="col-lg-4 pt-4 col-md-6">
                          <div class="box featured">
                            <h3>Bronze Package + Undangan Digital (Basic)</h3>
                            <h4><sup>Rp.</sup>365.000</h4>
                            <ul>
                                <li>8 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 pt-4 col-md-6 mt-4 mt-md-0">
                          <div class="box featured">
                            <h3>Silver Package + Undangan Digital (Basic)</h3>
                            <h4><sup>Rp.</sup>435.000</h4>
                            <ul>
                                <li>10 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Gold Package + Undangan Digital (Basic)</h3>
                            <h4><sup>Rp.</sup>510.000</h4>
                            <ul>
                                <li>12 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div> 
                        
                        <div class="col-lg-4 pt-4 col-md-6">
                          <div class="box featured">
                            <h3>Bronze Package + Undangan Digital (Standar)</h3>
                            <h4><sup>Rp.</sup>375.000</h4>
                            <ul>
                                <li>8 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 pt-4 col-md-6 mt-4 mt-md-0">
                          <div class="box featured">
                            <h3>Silver Package + Undangan Digital (Standar)</h3>
                            <h4><sup>Rp.</sup>445.000</h4>
                            <ul>
                                <li>10 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Gold Package + Undangan Digital (Standar)</h3>
                            <h4><sup>Rp.</sup>520.000</h4>
                            <ul>
                                <li>12 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div> 

                        <div class="col-lg-4 pt-4 col-md-6">
                          <div class="box featured">
                            <h3>Bronze Package + Undangan Digital (Premium)</h3>
                            <h4><sup>Rp.</sup>385.000</h4>
                            <ul>
                                <li>8 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 pt-4 col-md-6 mt-4 mt-md-0">
                          <div class="box featured">
                            <h3>Silver Package + Undangan Digital (Premium)</h3>
                            <h4><sup>Rp.</sup>465.000</h4>
                            <ul>
                                <li>10 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Gold Package + Undangan Digital (Premium)</h3>
                            <h4><sup>Rp.</sup>530.000</h4>
                            <ul>
                                <li>12 Akrilik Box</li>
                                <li>Wooden Tray</li>
                                <li>Bunga hias artificial premium</li>
                                <li>Tile putih</li>
                                <li>Free sewa ring box</li>                  
                                <li>Sudah termasuk jewelry box</li>
                                <li>Include cutting name ring box</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=62822986192411&text= Hai min, saya mau tanya untuk paket lengkap sewa box dan undangan digitalnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                </div>
              </div>
              <!-- MAHAR SECTION -->
              <div class="tab-pane fade" id="price_mahar" role="tabpanel" aria-labelledby="mahar-tab">
                <div class="col-md-12">                  
                  <ul id="features-filter" class="nav nav-pills justify-content-center" >
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="pill" href="#terarium-mahar" role="tab" >Terrarium Mahar</a>
                    </li>
                    <!-- <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="pill" href="#acrylic-mahar" role="tab">Acrylic Mahar</a>
                    </li> -->
                    <li class="nav-item">
                      <a class="nav-link"  data-bs-toggle="pill" href="#gunung-wayang" role="tab">Gunung Wayang Mahar</a>
                    </li>
                  </ul>
                </div>
                <div class="tab-content">
                    <div id="terarium-mahar" class="tab-pane fade show active">
                      <div class="col-md-12">                  
                        <ul id="features-filter" class="nav nav-pills justify-content-center" >
                          <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="pill" href="#luminary" role="tab" >Luminary Gold</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#luxe" role="tab">Luxe Simplicity</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#eternal" role="tab">Eternaglow</a>
                          </li>
                        </ul>
                      </div>
                      <div class="tab-content">
                          <div id="luminary" class="tab-pane fade show active">
                            <div class="row justify-content-center">
                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luminary Gold (20 x 30 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>630.000</h4>
                                  <ul>
                                    <li>Ukuran 20 x 30 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <span class="advanced">Best Seller</span>
                                  <h3>Luminary Gold (20 x 35 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>650.000</h4>
                                  <ul>
                                    <li>Ukuran 20 x 35 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luminary Gold (25 x 35 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>670.000</h4>
                                  <ul>
                                    <li>Ukuran 25 x 35 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luminary Gold (25 x 40 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>690.000</h4>
                                  <ul>
                                    <li>Ukuran 25 x 40 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luminary Gold (30 x 40 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>710.000</h4>
                                  <ul>
                                    <li>Ukuran 30 x 40 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luminary Gold (40 x 50 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>925.000</h4>
                                  <ul>
                                    <li>Ukuran 40 x 50 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="luxe" class="tab-pane fade">
                            <div class="row justify-content-center">
                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luxe Simplicity (20 x 30 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>615.000</h4>
                                  <ul>
                                    <li>Ukuran 20 x 30 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <span class="advanced">Best Seller</span>
                                  <h3>Luxe Simplicity (20 x 35 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>635.000</h4>
                                  <ul>
                                    <li>Ukuran 20 x 35 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luxe Simplicity (25 x 35 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>645.000</h4>
                                  <ul>
                                    <li>Ukuran 25 x 35 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luxe Simplicity (25 x 40 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>670.000</h4>
                                  <ul>
                                    <li>Ukuran 25 x 40 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luxe Simplicity (30 x 40 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>700.000</h4>
                                  <ul>
                                    <li>Ukuran 30 x 40 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Luxe Simplicity (40 x 50 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>925.000</h4>
                                  <ul>
                                    <li>Ukuran 40 x 50 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="eternal" class="tab-pane fade">
                            <div class="row justify-content-center">
                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Eternaglow (20 x 30 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>645.000</h4>
                                  <ul>
                                    <li>Ukuran 20 x 30 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <span class="advanced">Best Seller</span>
                                  <h3>Eternaglow (20 x 35 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>655.000</h4>
                                  <ul>
                                    <li>Ukuran 20 x 35 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <span class="advanced">Best Seller</span>
                                  <h3>Eternaglow (25 x 35 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>665.000</h4>
                                  <ul>
                                    <li>Ukuran 25 x 35 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Eternaglow (25 x 40 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>675.000</h4>
                                  <ul>
                                    <li>Ukuran 25 x 40 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Eternaglow (30 x 40 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>705.000</h4>
                                  <ul>
                                    <li>Ukuran 30 x 40 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                                <div class="box featured">
                                  <h3>Eternaglow (40 x 50 x 6 CM)</h3>
                                  <h4><sup>Rp.</sup>920.000</h4>
                                  <ul>
                                    <li>Ukuran 40 x 50 x 6 cm</li>
                                    <li>Bebas pilih tema warna bunga</li>
                                    <li>Additional replika Antam  15k/pcs</li>
                                    <li>Additional replika Koin 10k/pcs</li>
                                    <li>Additional replika Uang Kertas 3k/lembar</li>
                                    <li>Additional replika Cincin 5k/pcs</li>
                                    <li>Additional stand Kayu 20k</li>
                                  </ul>
                                  <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <!-- <div id="acrylic-mahar" class="tab-pane fade">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <h3>Hunter Green (35 x 25 CM)</h3>
                            <h4><sup>Rp.</sup>675.000</h4>
                            <ul>
                              <li>Ukuran 35 x 25 cm</li>
                              <li>Bebas pilih tema warna bunga</li>
                              <li>Bebas pilih font</li>
                              <li>Include Gantungan</li>
                              <li>Additional replika Antam  15k/pcs</li>
                              <li>Additional replika Koin 10k/pcs</li>
                              <li>Additional replika Uang Kertas 3k/lembar</li>
                              <li>Additional replika Cincin 5k/pcs</li>
                              <li>Additional stand Kayu 20k</li>
                              <li>Additional font color gold minor 40k</li>
                            </ul>
                            <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <h3>Blue Metalic (30 x 30 CM)</h3>
                            <h4><sup>Rp.</sup>650.000</h4>
                            <ul>
                              <li>Ukuran 30 x 30 cm</li>
                              <li>Bebas pilih tema warna bunga</li>
                              <li>Bebas pilih font</li>
                              <li>Include Gantungan</li>
                              <li>Additional replika Antam  15k/pcs</li>
                              <li>Additional replika Koin 10k/pcs</li>
                              <li>Additional replika Uang Kertas 3k/lembar</li>
                              <li>Additional replika Cincin 5k/pcs</li>
                              <li>Additional stand Kayu 20k</li>
                              <li>Additional font color gold minor 40k</li>
                            </ul>
                            <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div id="gunung-wayang" class="tab-pane fade">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <h3>Gunung Wayang Mahar (25 x 35 CM)</h3>
                            <h4><sup>Rp.</sup>755.000</h4>
                            <ul>
                              <li>Ukuran 25 x 35 cm</li>
                              <li>Bebas pilih tema warna bunga</li>
                              <li>Additional replika Antam  15k/pcs</li>
                              <li>Additional replika Koin 10k/pcs</li>
                              <li>Additional replika Uang Kertas 3k/lembar</li>
                              <li>Additional replika Cincin 5k/pcs</li>
                              <li>Additional stand Kayu 20k</li>
                            </ul>
                            <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <span class="advanced">Best Seller</span>
                            <h3>Gunung Wayang Mahar (30 x 40 CM)</h3>
                            <h4><sup>Rp.</sup>775.000</h4>
                            <ul>
                              <li>Ukuran 30 x 40 cm</li>
                              <li>Bebas pilih tema warna bunga</li>
                              <li>Additional replika Antam  15k/pcs</li>
                              <li>Additional replika Koin 10k/pcs</li>
                              <li>Additional replika Uang Kertas 3k/lembar</li>
                              <li>Additional replika Cincin 5k/pcs</li>
                              <li>Additional stand Kayu 20k</li>
                            </ul>
                            <div class="btn-wrap">
                                    <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                                    <br><br>
                                    <router-link
                                      :to="{name: 'Seserahan', hash: '#mahar'}"
                                      type="button"
                                      class="btn-buy"
                                      data-wow-delay=".6s"
                                      style="background:#BCA37F;"
                                    >
                                      Lihat Galeri
                                    </router-link>
                                  </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                          <div class="box featured">
                            <h3>Gunung Wayang Mahar (35 x 45 CM)</h3>
                            <h4><sup>Rp.</sup>795.000</h4>
                            <ul>
                              <li>Ukuran 35 x 45 cm</li>
                              <li>Bebas pilih tema warna bunga</li>
                              <li>Additional replika Antam  15k/pcs</li>
                              <li>Additional replika Koin 10k/pcs</li>
                              <li>Additional replika Uang Kertas 3k/lembar</li>
                              <li>Additional replika Cincin 5k/pcs</li>
                              <li>Additional stand Kayu 20k</li>
                            </ul>
                            <div class="btn-wrap">
                              <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                              <br><br>
                              <router-link
                                :to="{name: 'Seserahan', hash: '#mahar'}"
                                type="button"
                                class="btn-buy"
                                data-wow-delay=".6s"
                                style="background:#BCA37F;"
                              >
                                Lihat Galeri
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
              <!-- RINGBOX SECTION -->
              <div class="tab-pane fade" id="price_ringbox" role="tabpanel" aria-labelledby="ringbox-tab">
                <!-- <div class="col-md-12"> -->
                  
                  <ul id="features-filter" class="nav nav-pills justify-content-center" role="tab-list">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="pill" href="#luminarys" role="tab" >Luminary Gold</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="pill" href="#luxes" role="tab">Luxe Simplicity</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="pill" href="#eternals" role="tab">Eternaglow</a>
                      </li>
                  </ul>
                <!-- </div> -->
                <div class="tab-content">
                  <div id="luminarys" class="tab-pane fade show active">
                    <div class="row justify-content-center">
                      <div class="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0">
                        <div class="box featured">
                          <h3>Luminary Gold (25 x 20 x 6 CM)</h3>
                          <h4><sup>Rp.</sup>630.000</h4>
                          <ul>
                            <li>Ukuran 25 x 20 x 6 cm</li>
                            <li>Bebas pilih tema warna bunga</li>
                            <li>Additional replika Antam  15k/pcs</li>
                            <li>Additional replika Koin 10k/pcs</li>
                            <li>Additional replika Uang Kertas 3k/lembar</li>
                            <li>Additional replika Cincin 5k/pcs</li>
                            <li>Additional stand Kayu 20k</li>
                          </ul>
                          <div class="btn-wrap">
                            <a :href="'https://api.whatsapp.com/send?phone=6281316009990&text= Hai min, saya mau tanya untuk paket maharnya nih. Terima kasih'" target="_blank" class="btn-buy" style="background:#BCA37F;">Pesan Sekarang</a>
                            <br><br>
                            <router-link
                              :to="{name: 'Seserahan', hash: '#ringbox'}"
                              type="button"
                              class="btn-buy"
                              data-wow-delay=".6s"
                              style="background:#BCA37F;"
                            >
                              Lihat Galeri
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="luxes" class="tab-pane fade">
                    <div class="row justify-content-center">
                       Cooming Soon
                    </div>
                  </div>
                  <div id="eternals" class="tab-pane fade">
                    <div class="row justify-content-center">
                      Cooming Soon
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section><!-- End Pricing Section -->

      <!-- ======= Cta Section ======= -->
      <section id="cta" class="cta" style="background-color: rgb(255, 242, 216);">
        <div class="container">

          <div class="text-center">
            <h3 style="color: #BCA37F;"><b>Penting untuk diperhatikan</b></h3>
            <p style="color: black;"> Mohon dibaca terlebih dahulu rincian paket serta syarat & ketentuan yang berlaku sebelum anda melakukan pesanan</p>
            <!-- <a class="cta-btn" href="#"><i class='bx bx-edit'></i> Syarat & Ketentuan</a> -->
            <router-link
              :to="{name:'SnK'}"
              class="cta-btn"
            >
              <i class='bx bx-edit'></i> Syarat & Ketentuan
            </router-link>
          </div>

        </div>
      </section><!-- End Cta Section -->

      <!-- ======= Testimonials Section ======= -->
      
      <!-- End Testimonials Section -->

      <!-- ======= Portfolio Section ======= -->
      
      <!-- End Portfolio Section -->

      <!-- ======= Team Section ======= -->
      
      <!-- End Team Section -->

      <!-- ======= Frequently Asked Questions Section ======= -->
      
      <!-- End Frequently Asked Questions Section -->

      <!-- ======= Contact Section ======= -->
      <!-- End Contact Section -->
    </main>

    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

    <!-- Modal -->
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          
          <div class="modal-body" style="text-align: right;padding:5px;">
            <a href="#" type="button" @click="closeModal()" class="justify-content-center">
              <i class="bi bi-x-circle-fill"></i>
              </a>
            <a href="/claim-voucher" v-if="getVoucher">
              <img :src="'https://admin.itsyourdayofficial.com/image_banner/' + getVoucher.image_banner" class="img-fluid" alt="">
            </a>        
          </div>
          <div class="modal-footer justify-content-center">
            <a href="/claim-voucher" type="button" class="btn btn-warning" style="color: white;">Claim Voucher</a>
          </div>
        </div>
      </div>
    </div>
  </div>    
</template>
  
<script>
export default {
  name: 'HelloWorld',
  props: ['getVoucher'],
  components: {},
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      $('#exampleModalCenter').modal('hide');
    },
    scrollToPricingAndClickTab(tab) {
      // Gulir ke bagian "pricing"
      document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' });

      // Set timeout untuk memastikan scroll selesai sebelum memicu klik
      setTimeout(function() {
        // Pilih tab yang sesuai berdasarkan parameter `tab`
        switch (tab) {
          case 'seserahan':
            document.getElementById('seserahan-tab').click();
            break;
          case 'mahar':
            document.getElementById('mahar-tab').click();
            break;
          case 'ringbox':
            document.getElementById('ringbox-tab').click();
            break;
          case 'undangan':
            document.getElementById('undangan-tab').click();
            break;
          default:
            document.getElementById('seserahan-tab').click();
        }
      }, 1000); // 1000ms = 1 detik, sesuaikan jika perlu
    }
  },
}
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  background: #BCA37F;
  color: #ffffff;
}

.nav-pills .nav-link {
  cursor: pointer;
  display: inline-block;
  padding: 8px 18px 10px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #f4f5f8;
  border-radius: 50px;
}
</style>
  


